.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.gretting-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  margin-top: 80px;
  font-size: 70px;
  line-height: 1.1;
  font-family: "Google Sans Bold";
}

.greeting-nickname {
  font-size: 30px;
  line-height: 0px;
  font-family: "Google Sans Bold Italic";
}

.greeting-text-p {
  font-size: 40px;
  line-height: 40px;
  margin-right: 40px;
  font-family: "Google Sans Medium";
}

.greeting-image-div > * {
  max-width: 100%;
  height: auto;
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    margin-top: 0px;
    text-align: center;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.fancy-link {
  color: #3498db; /* Fancy color (blue in this case) */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Optional: make text bold */
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Smooth color and shadow transition */
}

.fancy-link:hover {
  color: #2ecc71; /* Different color on hover */
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Optional: add a shadow effect */
}

.fancy-link2 {
  color: #e74c3c; /* Fancy color (red in this case) */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Optional: make text bold */
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Smooth color and shadow transition */
}

.fancy-link2:hover {
  color: #f39c12; /* Different color on hover (orange) */
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.4); /* Add a shadow effect */
}
