/* Common button style */
.button {
  color: white;
  border: 0px;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  text-decoration: none;
  width: 400px;
  display: inline-block;
  text-align: center;
  font-family: "Google Sans Medium";
  margin: 2px 10px 2px 0px;
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

/* Default button style */
.default-btn {
  background-color: #9e9e9e;
}

.default-btn:hover {
  background-color: #757575;
}

/* arXiv button style */
.arxiv-btn {
  background-color: #d32f2f;
}

.arxiv-btn:hover {
  background-color: #b71c1c;
}

/* NeurIPS button style */
.neurips-btn {
  background-color: #00bcd4;
}

.neurips-btn:hover {
  background-color: #008ba3;
}

/* CVPR button style */
.cvpr-btn {
  background-color: #6c63ff;
}

.cvpr-btn:hover {
  background-color: #534aba;
}

/* ICCV button style */
.wacv-btn {
  background-color: #ff9800;
}

.wacv-btn:hover {
  background-color: #e65100;
}

/* AAAI button style */
.aaai-btn {
  background-color: #4caf50;
}

.aaai-btn:hover {
  background-color: #2e7d32;
}

/* MMM button style */
.mmm-btn {
  background-color: #ff5722;
}

.mmm-btn:hover {
  background-color: #e64a19;
}

/* ICMR button style */
.icmr-btn {
  background-color: #9c27b0;
}

.icmr-btn:hover {
  background-color: #7b1fa2;
}

/* CVPRW button style */
.cvprw-btn {
  background-color: #03a9f4;
}

.cvprw-btn:hover {
  background-color: #0288d1;
}

/* MediaEval button style */
.mediaeval-btn {
  background-color: #ffeb3b;
  color: black; /* Optional: since yellow background needs contrast */
}

.mediaeval-btn:hover {
  background-color: #fbc02d;
}
